export function state() {
  return {
    items: [],
  }
}

export const mutations = {
  setItems(state, data) {
    state.items = data
  },
}

export const actions = {
  async fetchItems({ commit }, params) {
    try {
      return await this.$axios
        .$get(`/reports`, {
          params,
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        })
        .then((data) => {
          data.data.totalPages = data.meta.total
          commit('setItems', data.data)
          return Promise.resolve(state.items)
        })
    }
    catch {}
  },
  downloadCsv(_, item) {
    this.$axios
      .$post(`/export/store-summary`, item, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      }).then((data) => {
        this.$notify({
          type: 'info',
          title: 'Downloading...',
          text: 'Please wait while we download the report.',
        })

        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'report.csv')
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      })
  },
}
